@import '../styles/defaults';

.footer {
    margin-top: 40px;
    padding: 40px 20px 40px 20px;
    background: $background-dark-color !important;
    text-align: center;
    color: $text-light-color !important;
    a {
        color: $primary-light-color;
        &:hover {
            color: $primary-color;
        }
    }
}

