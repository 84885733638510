@import 'styles/defaults';

.burger .burger-lines,
.burger .burger-lines:after,
.burger .burger-lines:before { background-color: #1c4064; }

.loading {
    text-align: center;
    margin-top: 200px;
    margin-bottom: 200px;
    h1 {
        margin-top: 50px;
    }
}

.page {
  color: #333333;
  background: #ffffff;

  p {
      font-size: 14px;
      margin-top: 15px;
      margin-bottom: 15px;
  }
  
  .ant-layout-header {
      background: #ffffff;
      @media only screen and (max-width: 800px) {
          padding: 0 0;
      }
      border-bottom: 5px solid #1c4064;
      height: 101px;
  }
  
  .header-burger-open {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      z-index: 100000;
  }
  
  .header-title {
    float: left;
    margin-right: 0px;
    margin-left: 0px;
    margin-bottom: 0;
    padding: 0 10px;
    a {
        img {
            height: 100px;
        }
    }
  }

  .header-burger {
      position: absolute;
      width: auto;
      height: auto;
      top: 13px;
      right: 20px;
      @media only screen and (min-width: 800px) {
          display: none;
      }
  }

  .menu {
        background: #ffffff;
        @media only screen and (max-width: 800px) {
            display: none;
        }
        ul {
            li {
                height: 100px;
                line-height: 100px;
                a {
                    font-size: 20px;
                    color: #1c4064;
                    &:hover {
                        color: #bca967;
                    }
                }
            }
        }
  }

  .menu-burger-open {
      display: block;
      position: fixed;
      top: 100px;
      left: 0;
      width: 100vw;
      height: calc(100vh - 100px);
      overflow-y: hidden;
      z-index: 100000;
      padding: 20px 0;
      background: #ffffff;
      border-top: 1px solid #1c4064;
  }

  .ant-menu {
      line-height: 64px;
  }

  .site-layout-content {
    background: #383232;
    //padding: 50px;
    min-height: 1000px;
  }

}

.ant-layout {
    background-color: #ffffff;
}

.ant-layout-content {
    max-width: 1200px;
    width: 100%;
    min-height: calc(100vh - 330px);
    margin: 0 auto 40px auto;
}

.ant-card {
    height: auto;
    border-radius: 3px;
    box-shadow: 0 0 50px -20px #555555;
}

.layout-dark {

}

.layout-light {
    > div {
    }
}

.content-title {
    margin: 80px 40px 20px 40px;
    padding: 0 40px 20px 40px;
    @media only screen and (max-width: 800px) {
        padding: 0 0 0 0;
    }
    h1 {
        margin: 40px 0 0 0;
    }
}

content-subtitle {
    margin-top: 40px;
    &:first-child {
        margin-top: 0;
    }
    font-size: 18px;
    font-stretch: expanded;
    font-weight: bold;
}

.content-body {
    padding: 0 40px 40px 40px;
    @media only screen and (max-width: 800px) {
        padding: 40px 0 40px 0;
    }

    margin-left: 40px;
    margin-right: 40px;
    
    p, ul, li, div {
        font-size: 18px;
        font-stretch: expanded;
    }

    hr {
        opacity: 0.5;
        border: 0;
        height: 1px;
        background-color: #1c4064;
    }
}

@media (max-width: 500px) {
   .ant-col {
       margin: 0;
   }

   .logo{
      max-width: 100%;
   }
}

