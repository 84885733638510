.cookies {
    height: 100px;
    background-color: #777777;
    &--popup {
        position: fixed;
        z-index: 10000;
        width: 100%;
        height: auto;
        bottom: 0;
        background: #000000;
        &__content {
            color: #ffffff;
            text-align: center;
            padding: 20px;
        }
    }
}
